import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import Manimgone from "./Manimgone";
import Exploreallseven from "../../Components/Explore/Exploreallseven";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function Manimgonemain() {
  useEffect(() => {
    document.title =
      "Enchanted Garden Tailored Blazer | Crowd Approved Fashion";
  }, []);
  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <Manimgone />
      <Exploreallseven />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default Manimgonemain;
