import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import Manimgfive from "./Manimgfive";
import ExploreallEleven from "../../Components/Explore/ExploreallEleven";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";
function Manimgfourmain() {
  useEffect(() => {
    document.title = "Heritage Elegance Kurta Set | Crowd Approved Fashion";
  }, []);
  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <Manimgfive />
      <ExploreallEleven />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default Manimgfourmain;
