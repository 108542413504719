import React from "react";
import ManImage1 from "../../Images/man-image-1.png";
import ManImage2 from "../../Images/man-image-2.png";
import womanImg2 from "../../Images/woman-img-2.png";
import womanImg5 from "../../Images/woman-img-5.jpg";
import man3 from "../../Images/man-3.png";
import man4 from "../../Images/man-4.png";
import man5 from "../../Images/man-5.png";
import man6 from "../../Images/man-6.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function MenFirstSec() {
  return (
    <div className="container">
      <div className="row image-container-hover-all">
        <div className="col-md-12">
          <div className="image-container-all">
            <Link to="/enchanted-garden-tailored-blazer">
              <LazyLoadImage
                effect="blur"
                className=" fluid-custom"
                src={ManImage1}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">
            Enchanted Garden Tailored Blazer
          </h4>
        </div>
      </div>
      <div className="elegence-flex image-container-hover-all">
        <div className="custom-me-3">
          <div className="image-container-all">
            <Link to="/sunset-serenade-artistic-blazer">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={ManImage2}
                alt=""
              />
            </Link>
          </div>
          <h4 className=" font-size my-2 custom-pb-five ">
            Sunset Serenade Artistic Blazer
          </h4>
        </div>
        <div className="">
          <div className="image-container-all">
            <Link to="/majestic-legacy-sherwanii">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={man3}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">
            Majestic Legacy Sherwani
          </h4>
        </div>
      </div>
      <div className="row image-container-hover-all">
        <div className="col-md-12">
          <div className="image-container-all">
            <Link to="/regal-embroidered-kurta-set">
              <LazyLoadImage
                effect="blur"
                className=" fluid-custom"
                src={man4}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">
            Regal Embroidered Kurta Set
          </h4>
        </div>
      </div>
      <div className="elegence-flex image-container-hover-all">
        <div className="custom-me-3">
          <div className="image-container-all">
            <Link to="/heritage-elegance-kurta-set">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={man5}
                alt=""
              />
            </Link>
          </div>
          <h4 className=" font-size my-2 custom-pb-five ">
            Heritage Elegance Kurta Set
          </h4>
        </div>
        <div className="">
          <div className="image-container-all">
            <Link to="/modern-majestic-dark-suit">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={man6}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">
            Modern Majestic Dark Suit
          </h4>
        </div>
      </div>
    </div>
  );
}

export default MenFirstSec;
