import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function formatBreadcrumbTitle(path) {
  return path
    .split('/')
    .map(part => 
      part
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    )
    .join('/');
}



function WebsiteBreadCrumbs() {
  const breadcrumbTitle = window.location.pathname.split("/");
  const formattedTitle = formatBreadcrumbTitle(breadcrumbTitle[1]);

  console.log("breadcrumbTitle: ", breadcrumbTitle);
  console.log("formattedTitle: ", formattedTitle);

  return (
    <div className='container py-4'>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item to="/Women">
          {formattedTitle}
        </Breadcrumb.Item>
        {/* <Breadcrumb.Item >Data</Breadcrumb.Item> */}
      </Breadcrumb>
    </div>
  )
}

export default WebsiteBreadCrumbs;
