import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import Womanimgsix from "./Womanimgsix";
import Exploreallfive from "../../Components/Explore/Exploreallfive";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function Womanimgsixmain() {
  useEffect(() => {
    document.title = "Executive Grace Office Dress | Crowd Approved Fashion";
  }, []);

  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <Womanimgsix />
      <Exploreallfive />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default Womanimgsixmain;
