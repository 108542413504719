import React from "react";
import Header from "../Global/Header";
import CattleBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import WomenImgFirstSec from "./WomenImgFirstSec";
import ExploreMen from "./ExploreMen";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function ShopMainComponent() {
  useEffect(() => {
    document.title = "Women | Crowd Approved Fashion";
  }, []);
  return (
    <div className="background-image body-color">
      <Header />
      <CattleBreadCrumbs />
      <WomenImgFirstSec />
      <ExploreMen />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default ShopMainComponent;
