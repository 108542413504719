import React from "react";
import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import TropicalImage from "../../Images/tropical-1.jpg";
import TropicalImageTwo from "../../Images/tropical-2.jpg";
import Executive1 from "../../Images/Executive-1.jpg";
import Executive2 from "../../Images/Executive-2.jpg";
import Modern1 from "../../Images/Modern-1.jpg";
import Modern2 from "../../Images/Modern-2.jpg";
import Blossom1 from "../../Images/Blossom-1.jpg";
import Blossom2 from "../../Images/Blossom-2.jpg";
import Majestic1 from "../../Images/Majestic-1.jpg";
import Majestic2 from "../../Images/Majestic-2.jpg";
import Heritage1 from "../../Images/Heritage-1.jpg";
import Heritage2 from "../../Images/Heritage-2.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";

function SectionTab() {
  const [activeKey, setActiveKey] = useState("link-1");

  const handleNavItemClick = (eventKey) => {
    setActiveKey(eventKey);
  };

  return (
    <div className="container tab-margin">
      <Nav
        className="Cormorant-font tab-space-between"
        activeKey={activeKey}
        onSelect={handleNavItemClick}
      >
        <Nav.Item>
          <Nav.Link eventKey="link-1">
            <h5 className="fw-bold tab-siz custom-py-two ">Executive Grace</h5>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-2">
            <h5 className="fw-bold tab-siz custom-py-two ">Modern Grace</h5>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-3">
            <h5 className="fw-bold tab-siz custom-py-two ">
              Tropical Sophistication
            </h5>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-4">
            <h5 className="fw-bold tab-siz custom-py-two ">Blossom Couture</h5>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-5">
            <h5 className="fw-bold tab-siz custom-py-two ">Majestic Legacy</h5>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-6">
            <h5 className="fw-bold tab-siz custom-py-two ">
              Heritage Elegance
            </h5>
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {activeKey === "link-1" && (
        <div className="row  image-container-hover-all mx-auto img-width">
          <div className="col-md-6 d-flex flex-column align-items-center ">
            <div className="image-container-all img-width">
              <Link to="/executive-grace-office-wear">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Executive1}
                  alt="Tropical Sophistication Formal Dress"
                />
              </Link>
            </div>
            <div className="w-100 ">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Executive Grace Office Wear
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/executive-grace-office-dress">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Executive2}
                  alt="Tropical Sophistication Casual Outfit"
                />
              </Link>
            </div>
            <div className="w-100 ">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Executive Grace Office Dress
              </p>
            </div>
          </div>
        </div>
      )}
      {activeKey === "link-2" && (
        <div className="row image-container-hover-all mx-auto img-width">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/teal-elegance-embroidered-suit">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Modern1}
                  alt="Tropical Sophistication Formal Dress"
                />
              </Link>
            </div>
            <div className="w-100 ">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Teal Elegance Embroidered Suit
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width ">
              <Link to="/regal-embroidery">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Modern2}
                  alt="Tropical Sophistication Casual Outfit"
                />
              </Link>
            </div>
            <div className="w-100 ">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Regal Embroidery
              </p>
            </div>
          </div>
        </div>
      )}
      {activeKey === "link-3" && (
        <div className="row image-container-hover-all mx-auto img-width">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/enchanted-garden-tailored-blazer">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={TropicalImage}
                  alt="Tropical Sophistication Formal Dress"
                />
              </Link>
            </div>
            <div className="w-100 ">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Enchanted Garden Tailored Blazer
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/sunset-serenade-artistic-blazer">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={TropicalImageTwo}
                  alt="Tropical Sophistication Casual Outfit"
                />
              </Link>
            </div>
            <div className="w-100">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Sunset Serenade Artistic Blazer
              </p>
            </div>
          </div>
        </div>
      )}
      {activeKey === "link-4" && (
        <div className="row image-container-hover-all mx-auto img-width">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/floral-maxi-dress">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Blossom1}
                  alt="Tropical Sophistication Formal Dress"
                />
              </Link>
            </div>
            <div className="w-100">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Floral Maxi Dress
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/majestic-legacy-sherwani">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Blossom2}
                  alt="Tropical Sophistication Casual Outfit"
                />
              </Link>
            </div>
            <div className="w-100">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Majestic Legacy Sherwani
              </p>
            </div>
          </div>
        </div>
      )}
      {activeKey === "link-5" && (
        <div className="row image-container-hover-all mx-auto img-width">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/majestic-legacy-sherwanii">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Majestic1}
                  alt="Tropical Sophistication Formal Dress"
                />
              </Link>
            </div>
            <div className="w-100">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Majestic Legacy Sherwani
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/modern-majestic-dark-suit">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Majestic2}
                  alt="Tropical Sophistication Casual Outfit"
                />
              </Link>
            </div>
            <div className="w-100">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Modern Majestic Dark Suit
              </p>
            </div>
          </div>
        </div>
      )}
      {activeKey === "link-6" && (
        <div className="row image-container-hover-all mx-auto img-width">
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/heritage-elegance-kurta-set">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Heritage1}
                  alt="Tropical Sophistication Formal Dress"
                />
              </Link>
            </div>
            <div className="w-100">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Heritage Elegance Kurta Set
              </p>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column align-items-center">
            <div className="image-container-all img-width">
              <Link to="/regal-embroidered-kurta-set">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Heritage2}
                  alt="Tropical Sophistication Casual Outfit"
                />
              </Link>
            </div>
            <div className="w-100">
              <p
                className="py-3 tab-img-text-color resp-ms-5"
                style={{ fontSize: "20px" }}
              >
                Regal Embroidered Kurta Set
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SectionTab;
