import React from "react";
import { Link } from "react-router-dom";

function Errorpage() {
  return (
    <div className="text-center align-self-center container">
      <h1>404</h1>
      <h1>Page Not Found</h1>
      <div className="text-center margin-py-marquee">
        <Link
          to={"/"}
          type="button"
          className="px-4 py-2 btn rounded-0 buttonhover  "
          style={{ border: "1px solid black" }}
        >
          Go to Home
        </Link>
      </div>
    </div>
  );
}

export default Errorpage;
