import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import MenFirstSec from "./MenFirstSec";
import ExploreWomen from "./ExploreWomen";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function MenMainComponent() {
  useEffect(() => {
    document.title = "Men | Crowd Approved Fashion";
  }, []);
  return (
    <div className="background-image body-color">
      <Header />
      <WebsiteBreadCrumbs />
      <MenFirstSec />
      <ExploreWomen />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default MenMainComponent;
