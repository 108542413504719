import React from "react";

import "react-awesome-slider/dist/styles.css";

// import test from "../Images/Logo.svg"
import cover1 from "../../Images/cover-1.jpg";
import cover2 from "../../Images/cover-2.jpg";
import cover3 from "../../Images/cover-3.jpg";
import cover4 from "../../Images/cover-4.jpg";
import cover5 from "../../Images/cover-5.jpg";
import cover6 from "../../Images/cover-6.jpg";
import cover1Small from "../../Images/cover-1-small.jpg";
import cover2Small from "../../Images/cover-2-small.jpg";
import cover3Small from "../../Images/cover-3-small.jpg";
import cover4Small from "../../Images/cover-4-small.jpg";
import cover5Small from "../../Images/cover-5-small.jpg";
import cover6Small from "../../Images/cover-6-small.jpg";

import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';

function Slider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div className=" container customm-container">
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        interval={300000}
        pause={false}
      >
        <Carousel.Item>
          <LazyLoadImage
            effect="blur"
            className=" fluid-custom web-display"
            src={cover1}
            alt=""
            // style={{ width: "auto" }}
          />
          <LazyLoadImage
            effect="blur"
            className=" img-fluid responsive-display"
            src={cover1Small}
            alt=""
            // style={{ width: "auto" }}
          />
          <Carousel.Caption className="custom-caption ">
            <p className="Cormorant-font">Blossom Couture</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <LazyLoadImage
            effect="blur"
            className=" fluid-custom web-display"
            src={cover2}
            alt=""
          />
          <LazyLoadImage
            effect="blur"
            className=" img-fluid responsive-display"
            src={cover2Small}
            alt=""
            // style={{ width: "auto" }}
          />
          <Carousel.Caption className="custom-caption">
            <p className="Cormorant-font">Heritage Elegance</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <LazyLoadImage
            effect="blur"
            className=" fluid-custom web-display"
            src={cover3}
            alt=""
          />
          <LazyLoadImage
            effect="blur"
            className=" img-fluid responsive-display"
            src={cover3Small}
            alt=""
            // style={{ width: "auto" }}
          />
          <Carousel.Caption className="custom-caption">
            <p className="Cormorant-font">Modern Grace</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <LazyLoadImage
            effect="blur"
            className=" fluid-custom web-display"
            src={cover4}
            alt=""
          />
          <LazyLoadImage
            effect="blur"
            className=" img-fluid responsive-display"
            src={cover4Small}
            alt=""
            // style={{ width: "auto" }}
          />
          <Carousel.Caption className="custom-caption">
            <p className="Cormorant-font">Tropical Sophistication</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <LazyLoadImage
            effect="blur"
            className=" fluid-custom web-display"
            src={cover5}
            alt=""
          />
          <LazyLoadImage
            effect="blur"
            className=" img-fluid responsive-display"
            src={cover5Small}
            alt=""
            // style={{ width: "auto" }}
          />
          <Carousel.Caption className="custom-caption">
            <p className="Cormorant-font">Executive Grace</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <LazyLoadImage
            effect="blur"
            className=" fluid-custom web-display"
            src={cover6}
            alt=""
          />
          <LazyLoadImage
            effect="blur"
            className=" img-fluid responsive-display"
            src={cover6Small}
            alt=""
            // style={{ width: "auto" }}
          />
          <Carousel.Caption className="custom-caption">
            <p className="Cormorant-font">Majestic Legacy</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>

    // <div className='container-fluid'>
    // <Carousel activeIndex={index} onSelect={handleSelect} interval={3000} pause={false}>
    //   <Carousel.Item>
    //     <img className='img-fluid' src={cover1} alt="" style={{width:"auto"}}/>
    //     {/* <ExampleCarouselImage src="../Images/cover-2.jpg" text="First slide" /> */}
    //     <Carousel.Caption>
    //       <h1>Blossom Couture </h1>
    //       {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //   <img className='img-fluid' src={cover2} alt="" />

    //     {/* <ExampleCarouselImage text="Second slide" /> */}
    //     <Carousel.Caption>
    //       <h1>Heritage Elegance</h1>
    //       {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //   <img src={cover3} alt="" />

    //     {/* <ExampleCarouselImage text="Third slide" /> */}
    //     <Carousel.Caption>
    //       <h1>Modern Grace</h1>
    //       {/* <p>
    //         Praesent commodo cursus magna, vel scelerisque nisl consectetur.
    //       </p> */}
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <img className='img-fluid' src={cover4} alt="" />
    //     {/* <ExampleCarouselImage src="../Images/cover-2.jpg" text="First slide" /> */}
    //     <Carousel.Caption>
    //       <h1>Tropical Sophistication</h1>
    //       {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <img className='img-fluid' src={cover5} alt="" />
    //     {/* <ExampleCarouselImage src="../Images/cover-2.jpg" text="First slide" /> */}
    //     <Carousel.Caption>
    //       <h1>Executive Grace</h1>
    //       {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <img className='img-fluid' src={cover6} alt="" />
    //     {/* <ExampleCarouselImage src="../Images/cover-2.jpg" text="First slide" /> */}
    //     <Carousel.Caption>
    //       <h1>Majestic Legacy</h1>
    //       {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
    //     </Carousel.Caption>
    //   </Carousel.Item>
    // </Carousel>
    // </div>
  );
}

export default Slider;
