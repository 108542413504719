import React from "react";
import womanImg1 from "../../Images/woman-img-1.png";
import womanImg2 from "../../Images/woman-img-2.png";
import womanImg3 from "../../Images/woman-img-3.png";
import womanImg4 from "../../Images/woman-img-4.jpg";
import womanImg4Small from "../../Images/woman-img-4-small.jpg";
import womanImg5 from "../../Images/woman-img-5.jpg";
import womanImg6 from "../../Images/woman-img-6.png";
import { Link } from "react-router-dom";
import Womanthree from "../../Images/woman-3.jpg";
import Womansix from "../../Images/woman-6.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function WomenImgFirstSec() {
  return (
    <div className="container">
      <div className="row image-container-hover-all">
        <div className="col-md-6">
          <div className="image-container-all">
            <Link to="/floral-maxi-dress">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={womanImg1}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">Floral Maxi Dress</h4>
        </div>
        <div className="col-md-6">
          <div className="image-container-all">
            <Link to="/majestic-legacy-sherwani">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={womanImg2}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">
            Majestic Legacy Sherwani
          </h4>
        </div>
        <div className="col-md-12">
          <div className="image-container-all">
            <Link to="/executive-grace-office-wear">
              <LazyLoadImage
                effect="blur"
                className=" fluid-custom"
                src={Womanthree}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">
            Executive Grace Office Wear
          </h4>
        </div>
      </div>
      <div className="elegence-flex image-container-hover-all">
        <div className="me-3">
          <div className="image-container-all">
            <Link to="/teal-elegance-embroidered-suit">
              <LazyLoadImage
                effect="blur"
                className="img-fluid web-display "
                src={womanImg4}
                alt=""
              />
              <LazyLoadImage
                effect="blur"
                className="img-fluid responsive-display"
                src={womanImg4Small}
                alt=""
              />
            </Link>
          </div>
          <h4 className=" font-size my-2 custom-pb-five ">
            Teal Elegance Embroidered Suit
          </h4>
        </div>
        <div className="">
          <div className="image-container-all">
            <Link to="/regal-embroidery">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={womanImg5}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">Regal Embroidery</h4>
        </div>
      </div>

      <div className="row image-container-hover-all">
        <div className="col-md-12">
          <div className="image-container-all">
            <Link to="/executive-grace-office-dress">
              <LazyLoadImage
                effect="blur"
                className=" fluid-custom"
                src={Womansix}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">
            Executive Grace Office Dress
          </h4>
        </div>
      </div>
    </div>
  );
}

export default WomenImgFirstSec;
