import { React, useState } from "react";
// import  useState  from "react";
import Manone1 from "../../Images/Man(1)1.png";
import Manone2 from "../../Images/Man(1)2.png";
import Manone3 from "../../Images/Man(1)3.png";
import Manone4 from "../../Images/Man(1)4.png";
import buttonImage from "../../Images/button-image.png";
// import SmallImg4 from "../../Images/woman-img-4.jpg";
// import DetailImage from "../../Images/woman-img-2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Manimgone() {
  const [selectedImage, setSelectedImage] = useState(Manone1);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="row reverse-col">
            <div className="col-md-3  "></div>
            <div className="col-md-2 col-xs-12 ">
              <div className="row">
                <div className="col-md-3  "></div>
                <div className="col-md-12 col-3 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manone1 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manone1)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manone1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manone2 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manone2)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manone2}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manone3 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manone3)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manone3}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manone4 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manone4)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manone4}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-xs-12 ">
              <LazyLoadImage
                effect="blur"
                className=" detail-image"
                src={selectedImage}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 detail-margn-left">
          <div className="res-images">
            <div className="image-container">
              <h1 className="Cormorant-font fw-bold responsive-one-rem">
                Enchanted Garden Tailored Blazer
              </h1>
              <h5 className=" ">BLD24FT037-SML-MNT</h5>
              {/* <div className=" my-3">
                <button
                  type="button"
                  className="px-4 py-1 btn rounded-0   "
                  style={{ border: "1px solid black" }}
                >
                  <span className="me-2">
                    <LazyLoadImage effect="blur" src={buttonImage} alt="" />
                  </span>
                  <span>Size Chart</span>
                </button>
              </div> */}
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Fit type:</span>
                <span className=""> Tailored fit</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Design details:</span>
                <span>
                  {" "}
                  This tailored blazer features a captivating botanical print
                  with a mix of exotic flowers and foliage in soothing pastel
                  hues on a light teal background. The design includes stylish
                  peak lapels, a structured shoulder, and a double-button front
                  closure that adds a classic touch to its modern aesthetic.
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Stretch:</span>
                <span> None</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Transparency: </span>
                <span> Opaque</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Hand Feel:</span>
                <span> Smooth with a structured drape</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Lining:</span>
                <span>
                  {" "}
                  Fully lined with a silky fabric for comfort & extra structure
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Material: </span>
                <span> 5 LYRA 95 COTTON</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Size:</span>
                <span> Premium blend of wool and silk</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Occasion:</span>
                <span> The model is 168cm/5'5" and wears a size MEDIUM</span>
              </div>
              <div className="py-4 Cormorant-font  note-bg">
                <span className="fw-bold ">Note:</span>
                <span>
                  {" "}
                  The actual color of the product may vary slightly from the
                  image.
                </span>
              </div>
            </div>
            <div className="text-container">
              {/* col-md-3  col-xs-3 col-sm-3 */}
              {/* <div className="d-flex">
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Manone1 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(Manone1)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Manone1}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Manone2 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(Manone2)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Manone2}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Manone3 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(Manone3)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Manone3}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Manone4 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(Manone4)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Manone4}
                    alt=""
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manimgone;
