import { React, useState } from "react";
// import  useState  from "react";
import Mantwo1 from "../../Images/Man(2)1.png";
import Mantwo2 from "../../Images/Man(2)2.png";
import Mantwo3 from "../../Images/Man(2)3.png";
import Mantwo4 from "../../Images/Man(2)4.png";
import buttonImage from "../../Images/button-image.png";
// import SmallImg4 from "../../Images/woman-img-4.jpg";
// import DetailImage from "../../Images/woman-img-2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Manimgtwo() {
  const [selectedImage, setSelectedImage] = useState(Mantwo1);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="row reverse-col">
            <div className="col-md-3  "></div>
            <div className="col-md-2 col-xs-12 ">
              <div className="row">
                <div className="col-md-12 col-3 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Mantwo1 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Mantwo1)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Mantwo1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Mantwo2 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Mantwo2)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Mantwo2}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Mantwo3 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Mantwo3)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Mantwo3}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Mantwo4 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Mantwo4)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Mantwo4}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-xs-12 ">
              <LazyLoadImage
                effect="blur"
                className=" detail-image"
                src={selectedImage}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 detail-margn-left">
          <div className="res-images">
            <div className="image-container">
              <h1 className="Cormorant-font fw-bold responsive-one-rem">
                Sunset Serenade Artistic Blazer
              </h1>
              <h5 className=" ">BLD24FT037-SML-MNT</h5>
              {/* <div className=" my-3">
                <button
                  type="button"
                  className="px-4 py-1 btn rounded-0   "
                  style={{ border: "1px solid black" }}
                >
                  <span className="me-2">
                    <LazyLoadImage effect="blur" src={buttonImage} alt="" />
                  </span>
                  <span>Size Chart</span>
                </button>
              </div> */}
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Fit type:</span>
                <span className=""> Slim fit</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Design details:</span>
                <span>
                  {" "}
                  This exquisite blazer features an artistic print with vibrant
                  brushstrokes in shades of gold, orange, and blue, reminiscent
                  of a sunset. The blazer is complemented by a matching golden
                  suit and a bright mustard shirt, making it a standout
                  ensemble. Notable for its sharp lapels and a single button
                  closure, the design merges traditional tailoring with a bold,
                  artistic expression.
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Stretch:</span>
                <span> None</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Transparency: </span>
                <span> Opaque</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Hand Feel:</span>
                <span> Silky smooth with a luxurious texture</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Lining:</span>
                <span>
                  {" "}
                  Fully lined with a breathable, satin-like material for added
                  comfort and fit
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Material: </span>
                <span> 5 LYRA 95 COTTON</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Size:</span>
                <span> The model is165.1cm/5'5" and wears a size SMALL</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Occasion:</span>
                <span> Daily casual</span>
              </div>
              <div className="py-4 Cormorant-font  note-bg">
                <span className="fw-bold ">Note:</span>
                <span>
                  {" "}
                  The actual color of the product may vary slightly from the
                  image.
                </span>
              </div>
            </div>
            <div className="text-container">
              {/* col-md-3  col-xs-3 col-sm-3 */}
              {/* <div className="d-flex">
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Mantwo1 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(Mantwo1)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Mantwo1}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Mantwo2 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(Mantwo2)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Mantwo2}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Mantwo3 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(Mantwo3)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Mantwo3}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Mantwo4 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(Mantwo4)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Mantwo4}
                    alt=""
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manimgtwo;
