import React from "react";
import lathranLogo from "../../Images/lathran-logo.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


function CompanyLogo() {
  return (
    <div className="container" style={{ backgroundColor: "#fff6ee" }}>
      <div className="engineer-display container-fluid ">
        <div className="mind-soul py-2">
          <p className="engineerp">Engineering Heart ❤️, Mind 🧠 & Soul ✨</p>
        </div>
        <div className="py-2">
          <LazyLoadImage effect="blur" src={lathranLogo} alt="" />
        </div>
      </div>
    </div>
  );
}

export default CompanyLogo;
