import React from "react";
import womanImg1 from "../../Images/woman-img-1.png";
import explorewoman1 from "../../Images/explore-woman-1.png";
import explorewoman2 from "../../Images/explore-woman-2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";

function ExploreWomen() {
  return (
    <div className="container p">
      <h2 className="py-3 Della-Respira explore fw-bold Cormorant-font">
        Explore Women
      </h2>
      <div className="row image-container-hover-all">
        <div className="col-md-6">
          <div className="image-container-all">
            <Link to="/majestic-legacy-sherwani">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={explorewoman1}
                alt=""
              />
            </Link>
          </div>
          <h4 className="font-size my-2 custom-pb-five">
            Majestic Legacy Sherwani
          </h4>
        </div>
        <div className="col-md-6">
          <div className="image-container-all">
            <Link to="/teal-elegance-embroidered-suit">
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src={explorewoman2}
                alt=""
              />
            </Link>
          </div>
          <h4 className=" my-2 custom-pb-five ">
            Teal Elegance Embroidered Suit
          </h4>
        </div>
      </div>
    </div>
  );
}

export default ExploreWomen;
