import React from "react";
import styleJourneyLeftImg from "../../Images/style-journey-left.svg";
import styleJourneyRightImg from "../../Images/style-journey-right.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function StylJourney() {
  return (
    <div
      className="  style-responsive   container"
      
    >
      <div className="style-display">
        <span className="styleJourneyLeftImg">
          <LazyLoadImage effect="blur" src={styleJourneyLeftImg} alt="" />
        </span>
        <span>
          {" "}
          <p className="style-journey-font cormorant-font text-center">
            Indulge in timeless elegance and boundless wanderlust. Discover the
            freedom of premium fashion and let your style journey begin.
          </p>{" "}
        </span>
        <span className="style-journey-font-image">
          <LazyLoadImage effect="blur"
            className="style-right-image"
            src={styleJourneyRightImg}
            alt=""
          />
        </span>
      </div>
    </div>
  );
}

export default StylJourney;
