import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import ExploreFirstSec from "./ExploreFirstSec";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import ExploreMen from "../../Components/Explore/ExploreMainComponent";
import { useEffect } from "react";

function ExploreMainComponent() {
  useEffect(() => {
    document.title = "Explore All | Crowd Approved Fashion";
  }, []);
  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <ExploreFirstSec />
      {/* <ExploreMen/> */}
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default ExploreMainComponent;
