import React from "react";
import womanImg1 from "../../Images/woman-img-1.png";
import ManImage2 from "../../Images/man-image-2.png";
import womanImg2 from "../../Images/woman-img-2.png";
import womanImg4 from "../../Images/woman-img-4.jpg";
import ManImage1 from "../../Images/man-image-1.png";
import womanImg5 from "../../Images/woman-img-5.jpg";
import exploreWomanImage1 from "../../Images/Explore-new-woman-image.png";
import exploreWomanImage2 from "../../Images/Explore-new-woman-image-2.png";
import exploreWomanImage3 from "../../Images/Explore-Woman-image-3.png";
import ExploreMen from "../../Components/Women-Shop/ExploreMen";
import Explore1 from "../../Images/explore-1.png";
import Explore4 from "../../Images/explore-4.png";
import rectangle1913 from "../../Images/Rectangle1913.png";
// import ExploreOne from "../../Images/explore-one.png";
// import ExploreTwo from "../../Images/Explore-two.png";
import Exploresix from "../../Images/explore-6.png";
import ExploresixSmall from "../../Images/explore-6-small.jpg";
import Exploreseven from "../../Images/explore-7.png";
import Exploreeight from "../../Images/exploe-8.png";
import Explorenine from "../../Images/explore-9.png";
import Exploreten from "../../Images/explore-10.png";
import Exploreeleven from "../../Images/explore-11.png";
import ExploreelevenSmall from "../../Images/explore-11-small.jpg";
import ExploreOne from "../../Images/exploreone.png";
import ExploreTwo from "../../Images/exploretwo.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function ExploreFirstSec() {
  // const data = [
  //   {
  //     id: 1,
  //     title: "Floral Maxi Dress",
  //     slug: "ExploreAll/details/floral-maxi-dress",
  //     source: ExploreOne,
  //   },
  //   {
  //     id: 2,
  //     title: "Sunset Serenade Artistic Blazer",
  //     slug: "ExploreAll/details/floral-maxi-dress",
  //     source: ExploreTwo,
  //   },
  //   {
  //     id: 3,
  //     title: "Enchanted Garden Tailored Blazer",
  //     slug: "ExploreAll/details/floral-maxi-dress",
  //     source: womanImg4,
  //   },
  // ];
  return (
    <div>
      {/* <div className="container d-flex gap-2 justify-content-between">
        {data.map((ele, i) => (
          <Link to={`Manimgtwo?name=${ele?.id}`}>
            <h5 className={`${ele?.id == 1 && "text-danger"}`}>{ele.title}</h5>
          </Link>
        ))}
      </div> */}
      <div className="container">
        <div className="elegence-flex image-container-hover-all">
          <div className="custom-me-3 ">
            <div className="image-container-all img-fluid ">
              <Link className="" to="/floral-maxi-dress">
                <LazyLoadImage
                  effect="blur"
                  className="inner-image img-fluid"
                  src={ExploreOne}
                  alt=""
                />
              </Link>
            </div>
            <h4 className=" font-size my-2 custom-pb-five ">
              Floral Maxi Dress
            </h4>
          </div>
          <div className="">
            <div className="image-container-all img-fluid">
              <Link className="" to="/sunset-serenade-artistic-blazer">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={ExploreTwo}
                  alt=""
                />
              </Link>
            </div>
            <h4 className="font-size my-2 custom-pb-five">
              Sunset Serenade Artistic Blazer
            </h4>
          </div>
        </div>
        <div className="elegence-flex image-container-hover-all">
          <div className="custom-me-3">
            <div className="image-container-all">
              <Link to="/teal-elegance-embroidered-suit">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={womanImg4}
                  alt=""
                />
              </Link>
            </div>
            <h4 className=" font-size my-2 custom-pb-five ">
              Teal Elegance Embroidered Suit
            </h4>
          </div>
          <div className="">
            <div className="image-container-all">
              <Link to="/majestic-legacy-sherwani">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={rectangle1913}
                  alt=""
                />
              </Link>
            </div>
            <h4 className="font-size my-2 custom-pb-five">
              Majestic Legacy Sherwani
              {/* Elegant Embroidered Suit */}
            </h4>
          </div>
        </div>
        <div className="row image-container-hover-all">
          <div className="col-md-12">
            <div className="image-container-all">
              <Link to="/enchanted-garden-tailored-blazer">
                <LazyLoadImage
                  effect="blur"
                  className=" fluid-custom"
                  src={ManImage1}
                  alt=""
                />
              </Link>
            </div>
            <h4 className="font-size my-2 custom-pb-five">
              Enchanted Garden Tailored Blazer
            </h4>
          </div>
        </div>
        <div className="elegence-flex image-container-hover-all">
          <div className="custom-me-3">
            <div className="image-container-all">
              <Link to="/executive-grace-office-wear">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid web-display "
                  src={Exploresix}
                  alt=""
                />
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid  responsive-display"
                  src={ExploresixSmall}
                  alt=""
                />
              </Link>
            </div>
            <h4 className="font-size my-2 custom-pb-five">
              Executive Grace Office Wear
            </h4>
          </div>
          <div className="custom-me-3">
            <div className="image-container-all">
              <Link to="/regal-embroidered-kurta-set">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Exploreseven}
                  alt=""
                />
              </Link>
            </div>
            <h4 className="font-size my-2 custom-pb-five">
              Regal Embroidered Kurta Set
            </h4>
          </div>
          <div className="">
            <div className="image-container-all">
              <Link to="/regal-embroidery">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={exploreWomanImage2}
                  alt=""
                />
              </Link>
            </div>

            <h4 className="font-size my-2 custom-pb-five">Regal Embroidery</h4>
          </div>
        </div>
        <div className="row image-container-hover-all">
          <div className="col-md-6">
            <div className="image-container-all">
              <Link to="/majestic-legacy-sherwanii">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Exploreeight}
                  alt=""
                />
              </Link>
            </div>

            <h4 className="font-size my-2 custom-pb-five">
              Majestic Legacy Sherwani
            </h4>
          </div>
          <div className="col-md-6">
            <div className="image-container-all">
              <Link to="/modern-majestic-dark-suit">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src={Explorenine}
                  alt=""
                />
              </Link>
            </div>

            <h4 className="font-size my-2 custom-pb-five">
              Modern Majestic Dark Suit
            </h4>
          </div>
        </div>
        <div className="elegence-flex image-container-hover-all">
          <div className="custom-me-3">
            <div className="image-container-all">
              <Link to="/executive-grace-office-dress">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid web-display"
                  src={Exploreten}
                  alt=""
                />
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid responsive-display"
                  src={ExploreelevenSmall}
                  alt=""
                />
              </Link>
            </div>
            <h4 className=" font-size my-2 custom-pb-five ">
              Executive Grace Office Dress
            </h4>
          </div>
          <div className="image-container-hover-all">
            <div className="image-container-all">
              <Link to="/heritage-elegance-kurta-set">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid "
                  src={Exploreeleven}
                  alt=""
                />
              </Link>
            </div>
            <h4 className="font-size my-2 custom-pb-five">
              Heritage Elegance Kurta Set
            </h4>
          </div>
        </div>
      </div>
      {/* <ExploreMen /> */}
    </div>
  );
}

export default ExploreFirstSec;
