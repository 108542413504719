import React from "react";
import NewBrandI from "../../Images/new-brand-image.svg";
import Crowdfrash from "../../Images/crowdfash-creations.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";

function NewBrand() {
  return (
    //   <div className="margin-py container-fluid">
    //   <div className="row">
    //     <div className="col-md-6 image-container-hover">
    //       <div className="image-container">
    //         <h1 className="py-4 style-weight Cormorant-font fw-bold" style={{ width: "60%", fontSize: "56px" }}>
    //           STYLE GUIDE JUNE, <span className="normal-text">2024</span>
    //         </h1>
    //         <div className="image-wrapper">
    //           <img
    //             className="mt-4 img-fluid inner-image"
    //             style={{ width: "99%" }}
    //             src={NewBrandI}
    //             alt=""
    //           />
    //         </div>
    //         <div className="text-center">
    //           <h2 className="image-text">NEW BRAND LAUNCH</h2>
    //           <h5 className="image-text-two">Traditional & Western</h5>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-md-6 image-container-hover">
    //       <div className="image-container">
    //         <h4 className="py-4" style={{ width: "80%" }}>
    //           Discover the latest trends and timeless designs that blend tradition with modernity.
    //         </h4>
    //         <div className="image-wrapper">
    //           <img
    //             className="mt-4 inner-image img-fluid"
    //             style={{ width: "99%" }}
    //             src={Crowdfrash}
    //             alt=""
    //           />
    //         </div>
    //         <div className="text-center">
    //           <h2 className="image-text-crowd">CROWDFASH CREATIONS</h2>
    //           <h5 className="image-text-two-crowd">Business Suite</h5>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="margin-py  container">
      <div className="row  mx-auto img-width">
        <div className="col-md-6 pe-3 ">
          <div className="">
            <h1 className="py-4 style-weight Cormorant-font fw-bold">
              STYLE GUIDE JUNE, <span className="normal-text"> 2024 </span>
            </h1>
            <div className="image-container-hover">
              <Link to={"/explore-all"}>
                <div className="image-container image-height">
                  <LazyLoadImage
                    effect="blur"
                    className="img-fluid   inner-image"
                    src={NewBrandI}
                    alt=""
                  />
                  <div className="text-center">
                    <h2 className="image-text Cormorant-font">
                      NEW BRAND LAUNCH
                    </h2>
                    <h5 className="image-text-two ">Traditional & Western</h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6 ps-3 image-container-hover">
          <div className="">
            <h4 className="py-4 " style={{ fontSize: "22px" }}>
              Discover the latest trends and timeless designs that blend
              tradition with modernity.
            </h4>
            <Link to={"/explore-all"}>
              <div className="image-container image-height">
                <LazyLoadImage
                  effect="blur"
                  className="  inner-image img-fluid"
                  src={Crowdfrash}
                  alt=""
                />
                <div className="text-center">
                  <h2 className="image-text-crowd Cormorant-font">
                    CROWDFASH CREATIONS
                  </h2>
                  <h5 className="image-text-two-crowd Cormorant-font">
                    Business Suite
                  </h5>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewBrand;
