import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import Womanimgtwo from "./Womanimgtwo";
import Explorealltwo from "../../Components/Explore/Explorealltwo";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function Womanimgtwomain() {
  useEffect(() => {
    document.title = "Majestic Legacy Sherwani | Crowd Approved Fashion";
  }, []);

  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <Womanimgtwo />
      <Explorealltwo />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default Womanimgtwomain;
