/*eslint-disable*/

import React, { useEffect } from "react";
import Header from "../Global/Header";
import Slider from "./Slider";
import NewBrand from "./NewBrand";
import CrowdFrash from "./CrowdFrash";
import MarqueeImages from "./MarqueeImages";
import Footer from "../Global/Footer";
import StylJourney from "./StylJourney";
import CompanyLogo from "../Global/CompanyLogo";
import Tab from "./SectionTab";
import { useState } from "react";

function MainComponent() {
  useEffect(() => {
    document.title = " | Crowd Approved Fashion";
  }, []);
  return (
    <div className="background-image body-color  ">
      <Header />
      <Slider />
      {/* <div className="d-flex container-fluid"> */}
      <NewBrand className=" me-5 " />
      {/* <CrowdFrash className="  ms-5"/> */}
      {/* </div> */}
      <Tab />
      <MarqueeImages />
      <StylJourney />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default MainComponent;
