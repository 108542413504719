import React, { useState, useRef } from "react";
import ContactImg from "../../Images/Contact-us-img.png";
import Form from "react-bootstrap/Form";
import emailjs from "@emailjs/browser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

function ContactFirstComponent() {
  const [isFocused1, setIsFocused1] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);
  const [isFocused3, setIsFocused3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFocus1 = () => setIsFocused1(true);
  const handleBlur1 = (event) => {
    if (event.target.value.length == 0) setIsFocused1(false);
  };

  const handleFocus2 = () => setIsFocused2(true);
  const handleBlur2 = (event) => {
    if (event.target.value.length === 0) setIsFocused2(false);
  };

  const handleFocus3 = () => setIsFocused3(true);
  const handleBlur3 = (event) => {
    if (event.target.value.length === 0) setIsFocused3(false);
  };

  const sendmail = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Thank You For Contacting us!",
      html: `
      <p>We have received your Message and will respond to you as soon as possible.</p>
  `,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(form.current);
    const toName = formData.get("to_name");
    const fromName = formData.get("from_name");
    const message = formData.get("message");

    if (!toName || !fromName || !message) {
      alert("Please fill in all fields before sending.");
      setIsLoading(false);
      return;
    }

    emailjs
      .sendForm(
        "service_tl60rdc",
        "template_z724a7a",
        form.current,
        "EkSDMto6z7Mg0Wu16"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          console.log("message sent successfully");
          form.current.reset();
          sendmail();
          setIsLoading(false);
        },
        (error) => {
          console.log("FAILED...", error);
          console.log("sending fail");
          setIsLoading(false);
        }
      );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" col-md-5">
          <LazyLoadImage
            className="img-fluid "
            effect="blur"
            src={ContactImg}
            alt=""
          />
        </div>
        <div className="Contact-main-text col-md-6">
          <form ref={form} onSubmit={sendEmail}>
            <div>
              <h1 className="Contact-text fw-bold Cormorant-font py-2 pt-5">
                Say Hello!
              </h1>
            </div>
            <div className="elegence-flex">
              <h5> Hello there, my name is </h5>
              <div className="form-width">
                <input
                  type="text"
                  name="to_name"
                  className={`form-control custom-input ${
                    isFocused1 ? "custom-input-focus" : ""
                  }`}
                  placeholder="your name here..."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onFocus={handleFocus1}
                  onBlur={handleBlur1}
                  onChange={handleFocus1}
                />
              </div>
            </div>
            <div className="elegence-flex email-margin">
              <h5> You can reach me at </h5>
              <div className="email-width">
                <input
                  type="text"
                  name="from_name"
                  className={`form-control custom-input ${
                    isFocused2 ? "custom-input-focus" : ""
                  }`}
                  placeholder=" your email address here..."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onFocus={handleFocus2}
                  onBlur={handleBlur2}
                  onChange={handleFocus2}
                />
              </div>
            </div>
            <div className="">
              <h5> and I’m looking for a help</h5>
              <div className="text-width">
                <textarea
                  name="message"
                  className={`form-control custom-text-area ${
                    isFocused3 ? "custom-input-focus" : ""
                  }`}
                  rows="1"
                  placeholder="write here..."
                  onFocus={handleFocus3}
                  onBlur={handleBlur3}
                  onChange={handleFocus3}
                  style={{ overflow: "hidden", resize: "none" }}
                  onInput={(e) => {
                    e.target.rows = 1;
                    const lineHeight = parseInt(
                      window.getComputedStyle(e.target).lineHeight
                    );
                    const lines = Math.floor(
                      e.target.scrollHeight / lineHeight
                    );
                    e.target.rows = lines < 8 ? lines : 8;
                    e.target.style.overflowY = lines >= 8 ? "scroll" : "hidden";
                  }}
                ></textarea>
              </div>
            </div>
            <div className=" py-5">
              <button
                disabled={isLoading}
                type="submit"
                className="px-4 btn rounded-0 buttonhover-send-message"
                style={{
                  border: "1px solid black",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="success" />
                ) : (
                  "Send Message"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactFirstComponent;
