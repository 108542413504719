import React from "react";
import Marquee from "react-fast-marquee";
import Marquee1 from "../../Images/marquee-image-1.svg";
import Marquee2 from "../../Images/marquee-image-2.svg";
import Marquee3 from "../../Images/marquee-image-3.svg";
import Marquee4 from "../../Images/marquee-image-4.svg";
import Marquee5 from "../../Images/marquee-image-5.svg";
import Marquee6 from "../../Images/marquee-image-6.svg";
import Marquee7 from "../../Images/marquee-image-7.svg";
import Marquee8 from "../../Images/marquee-image-8.svg";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function MarqueeImages() {
  return (
    <div className=" container">
      <div className="text-center">
        <h2 className="fw-bold Cormorant-font marquee-heading">
          EMPOWERING YOUR DAILY CONFIDENCE
        </h2>
        <p style={{ fontSize: "20px" }}>
          This collaboration celebrates the rich heritage of iconic fashion
          brands.
        </p>
      </div>
      <Marquee>
        <LazyLoadImage effect="blur" className="mx-2 " src={Marquee1} alt="" />
        <LazyLoadImage
          effect="blur"
          className="mx-2  marquee-image-mamrgin"
          src={Marquee2}
          alt=""
        />
        <LazyLoadImage effect="blur" className="mx-2 " src={Marquee3} alt="" />
        <LazyLoadImage
          effect="blur"
          className="mx-2  marquee-image-mamrgin"
          src={Marquee4}
          alt=""
        />
        <LazyLoadImage effect="blur" className="mx-2 " src={Marquee5} alt="" />
        <LazyLoadImage
          effect="blur"
          className="mx-2  marquee-image-mamrgin"
          src={Marquee6}
          alt=""
        />
        <LazyLoadImage effect="blur" className="mx-2 " src={Marquee7} alt="" />
        <LazyLoadImage
          effect="blur"
          className="mx-2  marquee-image-mamrgin"
          src={Marquee8}
          alt=""
        />
      </Marquee>

      <div className="text-center margin-py-marquee">
        <Link
          to={"/explore-all"}
          type="button"
          className="px-4 py-2 btn rounded-0 buttonhover  "
          style={{ border: "1px solid black" }}
        >
          Explore All
        </Link>
      </div>
    </div>
  );
}

export default MarqueeImages;
