import { React, useState } from "react";
// import  useState  from "react";
// import Explore1 from "../../Images/explore-1.png";
// import Explore2 from "../../Images/explore-2.png";
// import Explore3 from "../../Images/explore-3.png";
import womanthree1 from "../../Images/womanimag(3)1.png";
import womanthree2 from "../../Images/womanimag(3)2.png";
import womanthree3 from "../../Images/womanimag(3)3.png";
import womanthree4 from "../../Images/womanimag(3)4.png";
// import Explore4 from "../../Images/explore-4.png";
import buttonImage from "../../Images/button-image.png";
// import buttonimgtwo2 from "../../Images/woman(2)button.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Womanimgtwo() {
  const [selectedImage, setSelectedImage] = useState(womanthree1);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="row reverse-col">
              <div className="col-md-3  "></div>
              <div className="col-md-2 col-xs-12 ">
                <div className="row">
                  <div className="col-md-12 col-3 col-sm-3 col-xs-3">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womanthree1 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womanthree1)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womanthree1}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womanthree2 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womanthree2)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womanthree2}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womanthree3 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womanthree3)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womanthree3}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mar-top col-3 col-sm-3 col-xs-3">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womanthree4 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womanthree4)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womanthree4}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-xs-12 ">
                <LazyLoadImage
                  effect="blur"
                  className=" detail-image"
                  src={selectedImage}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 detail-margn-left">
            <div className="res-images">
              <div className="image-container">
                <h1 className="Cormorant-font fw-bold responsive-one-rem">
                  Executive Grace Office Wear
                </h1>
                <h5 className=" ">BLD24FT037-SML-MNT</h5>
                {/* <div className=" my-3">
                  <button
                    type="button"
                    className="px-4 py-1 btn rounded-0   "
                    style={{ border: "1px solid black" }}
                  >
                    <span className="me-2">
                      <LazyLoadImage effect="blur" src={buttonImage} alt="" />
                    </span>
                    <span>Size Chart</span>
                  </button>
                </div> */}
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Fit type:</span>
                  <span className=""> Tailored fit</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Design details:</span>
                  <span>
                    This elegant office wear set includes a tailored blazer and
                    a pencil skirt, both designed to offer a sophisticated and
                    polished look. The blazer features sharp lapels and a
                    single-button closure, creating a sleek silhouette, while
                    the pencil skirt complements the blazer with its knee-length
                    cut and refined fit.
                  </span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Stretch:</span>
                  <span>
                    {" "}
                    Moderate stretch for comfort and flexibility throughout the
                    workday.
                  </span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Transparency: </span>
                  <span> Opaque</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Hand Feel:</span>
                  <span> Smooth</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Lining:</span>
                  <span> Fully lined</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Material: </span>
                  <span> High-quality blend of polyester and elastane</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Size:</span>
                  <span> The model is 156 cm/5'3" and wears a size MEDIUM</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Occasion:</span>
                  <span> Formal Office wear</span>
                </div>
                <div className="py-4 Cormorant-font  note-bg">
                  <span className="fw-bold ">Note:</span>
                  <span>
                    {" "}
                    The actual color of the product may vary slightly from the
                    image.
                  </span>
                </div>
              </div>
              <div className="image-container">
                {/* col-md-3  col-xs-3 col-sm-3 */}

                {/* <div className="d-flex">
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womanthree1 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(womanthree1)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womanthree1}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womanthree2 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(womanthree2)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womanthree2}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womanthree3 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(womanthree3)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womanthree3}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womanthree4 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(womanthree4)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womanthree4}
                    alt=""
                  />
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Womanimgtwo;
