import React, { useEffect } from "react";
import Logo from "../../Images/Logo.svg";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// import { Link } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Header() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Navbar expand="lg" className="container px-0 header-transparent  ">
      <Container fluid className="header-position py-4">
        <Link to="/">
          <LazyLoadImage
            effect="blur"
            className="img-fluid"
            src={Logo}
            alt=""
          />
        </Link>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto ms-auto my-2 my-lg-0 nav-height"
            // style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Link className="mx-3 nav-link nav-text-hover" to="/explore-all">
              <span> Explore All </span>{" "}
            </Link>
            <Link className="mx-3 nav-link nav-text-hover" to="/women">
              <span> Women </span>
            </Link>
            <Link className="mx-3 nav-link  nav-text-hover" to="/men">
              <span>Men</span>
            </Link>
            {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action4">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action5">
              Something else here
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="#" disabled>
            Link
          </Nav.Link> */}
          </Nav>
          <Form className="d-flex">
            {/* <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
          /> */}
            <Link to="/contact-us">
              <Button
                style={{ border: "1px solid black", fontSize: "20px" }}
                className="buttonhover rounded-0 px-4 py-2"
              >
                Contact Us
              </Button>
            </Link>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    // <div className="header-transparent " style={{height:"100px"}}>
    // <div className="py-4  container-fluid  header-position">
    //   <div className="d-flex justify-content-between align-items-center">
    //     <div>
    //       <img className="img-fluid" src={Logo} alt="" />
    //     </div>
    //     <div className="Della-Respira">

    //     <span className="mx-3">Explore All</span>
    //       <span className="mx-3">Women</span>
    //       <span className="mx-3">Men</span>
    //     </div>
    //     <div className="buttonhover">
    //       <button type="button " className="p-2 buttonhover">Contact Us</button>
    //     </div>
    //   </div>
    // </div>
    // </div>
  );
}

export default Header;
