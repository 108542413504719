import React from "react";
import womanImg4 from "../../Images/exploreall(1)1.png";
import manImg2 from "../../Images/exploreall(2)2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";

function Exploreall() {
  return (
    <div className="container">
      <h2 className="py-3 Della-Respira explore fw-bold">Explore All</h2>
      <div className="row">
        <div className="col-md-6">
          <Link to="/teal-elegance-embroidered-suit">
            <LazyLoadImage
              className="img-fluid"
              effect="blur"
              src={womanImg4}
              alt=""
            />
          </Link>
          <h4 className="font-size py-2 pb-5">
            Teal Elegance Embroidered Suit
          </h4>
        </div>
        <div className="col-md-6">
          <Link to="/executive-grace-office-wear">
            <LazyLoadImage
              className="img-fluid"
              effect="blur"
              src={manImg2}
              alt=""
            />
          </Link>
          <h4 className="font-size py-2 ">Executive Grace Office Wear</h4>
        </div>
      </div>
    </div>
  );
}

export default Exploreall;
