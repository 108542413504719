import { React, useState } from "react";
// import  useState  from "react";
import Manfive1 from "../../Images/Man(5)1.png";
import Manfive2 from "../../Images/Man(5)2.png";
// import Manfour3 from "../../Images/Man(4)3.png";
// import Mantwo4 from "../../Images/Man(2)4.png";
import buttonImage from "../../Images/button-image.png";
// import SmallImg4 from "../../Images/woman-img-4.jpg";
// import DetailImage from "../../Images/woman-img-2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Manimgfive() {
  const [selectedImage, setSelectedImage] = useState(Manfive1);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="row reverse-col">
            <div className="col-md-3  "></div>
            <div className="col-md-2 col-xs-12 ">
              <div className="row">
                <div className="col-md-12 col-4 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manfive1 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manfive1)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manfive1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 col-4 mar-top col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manfive2 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manfive2)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manfive2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-xs-12 ">
              <LazyLoadImage
                effect="blur"
                className=" detail-image"
                src={selectedImage}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 detail-margn-left">
          <div className="res-images">
            <div className="image-container">
              <h1 className="Cormorant-font fw-bold responsive-one-rem">
                Heritage Elegance Kurta Set
              </h1>
              <h5 className=" ">BLD24FT037-SML-MNT</h5>
              {/* <div className=" my-3">
                <button
                  type="button"
                  className="px-4 py-1 btn rounded-0   "
                  style={{ border: "1px solid black" }}
                >
                  <span className="me-2">
                    <LazyLoadImage effect="blur" src={buttonImage} alt="" />
                  </span>
                  <span>Size Chart</span>
                </button>
              </div> */}
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Fit type:</span>
                <span className=""> Regular Fit</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Design details:</span>
                <span>
                  {" "}
                  This exquisite collection features a range of elegantly
                  designed kurtas paired with comfortable shalwar's. Each kurta
                  showcases intricate embroidery and rich printed patterns,
                  epitomizing traditional craftsmanship. The designs incorporate
                  a rich color palette with shades of gold, gray, navy blue, and
                  maroon, tailored to project a regal and sophisticated look.
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Stretch:</span>
                <span> Minimal</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Transparency: </span>
                <span> Opaque</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Hand Feel:</span>
                <span> Soft and luxurious</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Lining:</span>
                <span>
                  {" "}
                  Unlined for a lightweight feel, perfect for various climates.
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Material: </span>
                <span>
                  {" "}
                  Crafted from premium fabrics that blend natural fibers for
                  breathability and durability.
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Size:</span>
                <span> The model is 167cm/5'5" and wears a size LARGE.</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Occasion:</span>
                <span> Formal Events, Business Meetings, or Weddings</span>
              </div>
              <div className="py-4 Cormorant-font  note-bg">
                <span className="fw-bold ">Note:</span>
                <span>
                  {" "}
                  The actual color of the product may vary slightly from the
                  image.
                </span>
              </div>
            </div>
            <div className="text-container">
              {/* col-md-3  col-xs-3 col-sm-3 */}
              {/* <div className="d-flex">
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Manfive1 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage
                  effect="blur"
                    onClick={() => handleImageClick(Manfive1)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Manfive1}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === Manfive2 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage
                  effect="blur"
                    onClick={() => handleImageClick(Manfive2)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={Manfive2}
                    alt=""
                  />
                </div>
              
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manimgfive;
