import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import Manimgfour from "./Manimgfour";
import Explorealltwelve from "../Explore/Explorealltwelve";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function Manimgfourmain() {
  useEffect(() => {
    document.title = "Regal Embroidered Kurta Set | Crowd Approved Fashion";
  }, []);
  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <Manimgfour />
      <Explorealltwelve />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default Manimgfourmain;
