import { React, useState } from "react";
// import  useState  from "react";
// import Explore1 from "../../Images/explore-1.png";
// import Explore2 from "../../Images/explore-2.png";
// import Explore3 from "../../Images/explore-3.png";
import womantwo1 from "../../Images/woman(2)1.png";
import womantwo2 from "../../Images/woman(2)2.png";
import womantwo3 from "../../Images/woman(2)3.png";
// import Explore4 from "../../Images/explore-4.png";
import buttonImage from "../../Images/button-image.png";
// import buttonimgtwo2 from "../../Images/woman(2)button.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Womanimgtwo() {
  const [selectedImage, setSelectedImage] = useState(womantwo1);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="row reverse-col">
              <div className="col-md-3  "></div>
              <div className="col-md-2 col-xs-12 ">
                <div className="row">
                  <div className="col-md-12 col-4 col-sm-4 col-xs-4">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womantwo1 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womantwo1)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womantwo1}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-4 mar-top col-sm-4 col-xs-4">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womantwo2 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womantwo2)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womantwo2}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-4 mar-top col-sm-4 col-xs-4">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womantwo3 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womantwo3)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womantwo3}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-xs-12 ">
                <LazyLoadImage
                  effect="blur"
                  className=" detail-image"
                  src={selectedImage}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 detail-margn-left">
            <div className="res-images">
              <div className="image-container">
                <h1 className="Cormorant-font fw-bold responsive-one-rem">
                  Majestic Legacy Sherwani
                </h1>
                <h5 className=" ">BLD24FT037-SML-MNT</h5>
                {/* <div className=" my-3">
                  <button
                    type="button"
                    className="px-4 py-1 btn rounded-0   "
                    style={{ border: "1px solid black" }}
                  >
                    <span className="me-2">
                      <LazyLoadImage effect="blur" src={buttonImage} alt="" />
                    </span>
                    <span>Size Chart</span>
                  </button>
                </div> */}
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Fit type:</span>
                  <span className=""> Tailored Fit</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Design details:</span>
                  <span>
                    This sherwani boasts a high collar and structured shoulders,
                    crafted from luxurious navy blue fabric adorned with
                    intricate gold embroidery. The design features detailed
                    floral and vine patterns, prominently displayed across the
                    collar, cuffs, and the full length of the front panel. The
                    sherwani achieves a regal and sophisticated appearance,
                    suitable for formal events.
                  </span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Stretch:</span>
                  <span> None</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Transparency: </span>
                  <span> Opaque</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Hand Feel:</span>
                  <span> Smooth with textured embroidery</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Lining:</span>
                  <span>
                    {" "}
                    Fully lined with a smooth, satin finish for comfortable wear
                  </span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Material: </span>
                  <span>
                    {" "}
                    High-quality brocade fabric for the main body, with gold
                    thread embroidery
                  </span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Size:</span>
                  <span>
                    {" "}
                    The model is 165.1cm/5'5" and wears a size MEDIUM
                  </span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Occasion:</span>
                  <span> Wedding & Cultural Festivities</span>
                </div>
                <div className="py-4 Cormorant-font  note-bg">
                  <span className="fw-bold ">Note:</span>
                  <span>
                    {" "}
                    The actual color of the product may vary slightly from the
                    image.
                  </span>
                </div>
              </div>
              <div className="text-container">
                {/* col-md-3  col-xs-3 col-sm-3 */}
                {/* <div className="d-flex">
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womantwo1 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(womantwo1)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womantwo1}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womantwo2 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(womantwo2)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womantwo2}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womantwo3 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage effect="blur"
                    onClick={() => handleImageClick(womantwo3)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womantwo3}
                    alt=""
                  />
                </div>
                
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Womanimgtwo;
