import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import Manimgthree from "./Manimgthree";
import Exploreallnine from "../../Components/Explore/Exploreallnine";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function Manimgonemain() {
  useEffect(() => {
    document.title = "Majestic Legacy Sherwani | Crowd Approved Fashion";
  }, []);
  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <Manimgthree />
      <Exploreallnine />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default Manimgonemain;
