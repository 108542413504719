import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import Womanimgfive from "./Womanimgfive";
import Exploreallfour from "../../Components/Explore/Exploreallfour";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function Womanimgfivemain() {
  useEffect(() => {
    document.title = "Regal Embroidery | Crowd Approved Fashion";
  }, []);

  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <Womanimgfive />
      <Exploreallfour />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default Womanimgfivemain;
