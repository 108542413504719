import { React, useState } from "react";
// import  useState  from "react";
import Manfour1 from "../../Images/Man(4)1.png";
import Manfour2 from "../../Images/Man(4)2.png";
import Manfour3 from "../../Images/Man(4)3.png";
// import Mantwo4 from "../../Images/Man(2)4.png";
import buttonImage from "../../Images/button-image.png";
// import SmallImg4 from "../../Images/woman-img-4.jpg";
// import DetailImage from "../../Images/woman-img-2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Manimgfour() {
  const [selectedImage, setSelectedImage] = useState(Manfour1);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="row reverse-col">
            <div className="col-md-3  "></div>
            <div className="col-md-2 col-xs-12 ">
              <div className="row">
                <div className="col-md-12 col-4 col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manfour1 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manfour1)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manfour1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 col-4 mar-top col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manfour2 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manfour2)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manfour2}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-12 col-4 mar-top col-sm-3 col-xs-3">
                  <div
                    className={` res-top-mar ${
                      selectedImage === Manfour3 ? "selected-image" : ""
                    }`}
                  >
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => handleImageClick(Manfour3)}
                      style={{ cursor: "pointer" }}
                      className="img-fluid"
                      src={Manfour3}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-xs-12 ">
              <LazyLoadImage
                className=" detail-image"
                src={selectedImage}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 detail-margn-left">
          <div className="res-images">
            <div className="image-container">
              <h1 className="Cormorant-font fw-bold responsive-one-rem">
                Regal Embroidered Kurta Set
              </h1>
              <h5 className=" ">BLD24FT037-SML-MNT</h5>
              {/* <div className=" my-3">
                <button
                  type="button"
                  className="px-4 py-1 btn rounded-0   "
                  style={{ border: "1px solid black" }}
                >
                  <span className="me-2">
                    <LazyLoadImage effect="blur" src={buttonImage} alt="" />
                  </span>
                  <span>Size Chart</span>
                </button>
              </div> */}
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Fit type:</span>
                <span className=""> Custom Fit</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Design details:</span>
                <span>
                  {" "}
                  This collection features opulently embroidered kurtas on a
                  deep green silk base, reflecting the rich cultural heritage.
                  The meticulous gold embroidery extends from the neckline to
                  the hem and cuffs, adding a regal touch to the attire. Each
                  piece is paired with matching shalwars that complement the
                  kurta's elegant design.
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Stretch:</span>
                <span> None</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Transparency: </span>
                <span> Opaque</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Hand Feel:</span>
                <span> Soft and luxurious</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Lining:</span>
                <span>
                  {" "}
                  No lining, allowing the silk to drape naturally and
                  comfortably.
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Material: </span>
                <span>
                  {" "}
                  100% pure silk, renowned for its luster and fine texture.
                </span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Size:</span>
                <span> The model is 167cm/5'5" and wears a size LARGE.</span>
              </div>
              <div className="py-1 Cormorant-font fit-font-family">
                <span className="fw-bold ">Occasion:</span>
                <span> Formal Events, Business Meetings, or Weddings</span>
              </div>
              <div className="py-4 Cormorant-font  note-bg">
                <span className="fw-bold ">Note:</span>
                <span>
                  {" "}
                  The actual color of the product may vary slightly from the
                  image.
                </span>
              </div>
            </div>
            <div className="text-container">
              {/* col-md-3  col-xs-3 col-sm-3 */}
              {/* <div className="d-flex">
              <div
                className={`mx-2 res-top-mar ${
                  selectedImage === Manfour1 ? "selected-image" : ""
                }`}
              >
                <LazyLoadImage
                effect="blur"
                  onClick={() => handleImageClick(Manfour1)}
                  style={{ cursor: "pointer" }}
                  className="img-fluid"
                  src={Manfour1}
                  alt=""
                />
              </div>
              <div
                className={`mx-2 res-top-mar ${
                  selectedImage === Manfour2 ? "selected-image" : ""
                }`}
              >
                <LazyLoadImage
                effect="blur"
                  onClick={() => handleImageClick(Manfour2)}
                  style={{ cursor: "pointer" }}
                  className="img-fluid"
                  src={Manfour2}
                  alt=""
                />
              </div>
              <div
                className={`mx-2 res-top-mar ${
                  selectedImage === Manfour3 ? "selected-image" : ""
                }`}
              >
                <LazyLoadImage
                effect="blur"
                  onClick={() => handleImageClick(Manfour3)}
                  style={{ cursor: "pointer" }}
                  className="img-fluid"
                  src={Manfour3}
                  alt=""
                />
              </div>
            
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manimgfour;
