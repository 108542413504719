import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Slider from "./Components/Home/Slider";
import MainComponent from "./Components/Home/MainComponent";
import ShopMainComponent from "./Components/Women-Shop/ShopMainComponent";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MenMainComponent from "./Components/Men-Shop/MenMainComponent";
import DetailMainComponent from "./Components/WomanDetail/DetailMainComponent";
import ExploreMainComponent from "./Components/Explore/ExploreMainComponent";
import ContactMainComponent from "./Components/Contact-Us/ContactMainComponent";
import Womanimgtwomain from "./Components/WomanDetail/Womanimgtwomain";
import Womanimgthreemain from "./Components/WomanDetail/Womanimgthreemain";
import Womanimgfourmain from "./Components/WomanDetail/Womanimgfourmain";
import Womanimgfivemain from "./Components/WomanDetail/Womanimgfivemain";
import Womanimgsixmain from "./Components/WomanDetail/Womanimgsixmain";
import Manimgonemain from "./Components/ManDetail/Manimgonemain";
import Manimgtwomain from "./Components/ManDetail/Manimgtwomain";
import Manimgthreemain from "./Components/ManDetail/Manimgthreemain";
import Manimgfourmain from "./Components/ManDetail/Manimgfourmain";
import Manimgfivemain from "./Components/ManDetail/Manimgfivemain";
import Manimgsixmain from "./Components/ManDetail/Manimgsixmain";
import Errorpage from "./Components/Global/Errorpage";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<MainComponent />} />
        <Route path="/explore-all" element={<ExploreMainComponent />} />
        <Route path="/women" element={<ShopMainComponent />} />
        <Route path="/men" element={<MenMainComponent />} />
        <Route path="/contact-us" element={<ContactMainComponent />} />

        <Route path="/floral-maxi-dress" element={<DetailMainComponent />} />
        <Route path="/majestic-legacy-sherwani" element={<Womanimgtwomain />} />
        <Route
          path="/executive-grace-office-wear"
          element={<Womanimgthreemain />}
        />
        <Route
          path="/teal-elegance-embroidered-suit"
          element={<Womanimgfourmain />}
        />
        <Route path="/regal-embroidery" element={<Womanimgfivemain />} />
        <Route
          path="/executive-grace-office-dress"
          element={<Womanimgsixmain />}
        />
        <Route
          path="/enchanted-garden-tailored-blazer"
          element={<Manimgonemain />}
        />
        <Route
          path="/sunset-serenade-artistic-blazer"
          element={<Manimgtwomain />}
        />
        <Route
          path="/majestic-legacy-sherwanii"
          element={<Manimgthreemain />}
        />
        <Route
          path="/regal-embroidered-kurta-set"
          element={<Manimgfourmain />}
        />
        <Route
          path="/heritage-elegance-kurta-set"
          element={<Manimgfivemain />}
        />
        <Route path="/modern-majestic-dark-suit" element={<Manimgsixmain />} />
        <Route path="/*" element={<Errorpage />} />
      </Routes>
      {/* <Routes>
        <Route
          path="/myfolders"
          element={<ProtectedRoute path=""  element={<MainComponent />} />}
        />
        <Route path="/Women" element={<ProtectedRoute element={<ShopMainComponent />} />} />
      </Routes> */}
      {/* <MainComponent/>    
<ShopMainComponent/> */}
    </div>
  );
}

export default App;
