import { React, useState } from "react";
// import  useState  from "react";
// import Explore1 from "../../Images/explore-1.png";
// import Explore2 from "../../Images/explore-2.png";
// import Explore3 from "../../Images/explore-3.png";
import womanfour1 from "../../Images/woman(4)1.png";
import womanfour2 from "../../Images/woman(4)2.png";
import womanfour3 from "../../Images/woman(4)3.png";
// import womanthree4 from "../../Images/womanimag(3)4.png";
// import Explore4 from "../../Images/explore-4.png";
import buttonImage from "../../Images/button-image.png";
// import buttonimgtwo2 from "../../Images/woman(2)button.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Womanimhfour() {
  const [selectedImage, setSelectedImage] = useState(womanfour1);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="row reverse-col">
              <div className="col-md-3  "></div>

              <div className="col-md-2 col-xs-12 ">
                <div className="row">
                  <div className="col-md-12 col-4 col-sm-4 col-xs-4">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womanfour1 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womanfour1)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womanfour1}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mar-top col-4 col-sm-4 col-xs-4">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womanfour2 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womanfour2)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womanfour2}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mar-top col-4 col-sm-4 col-xs-4">
                    <div
                      className={` res-top-mar ${
                        selectedImage === womanfour3 ? "selected-image" : ""
                      }`}
                    >
                      <LazyLoadImage
                        effect="blur"
                        onClick={() => handleImageClick(womanfour3)}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        src={womanfour3}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 mar-top col-xs-12 ">
                <LazyLoadImage
                  effect="blur"
                  className=" detail-image"
                  src={selectedImage}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 detail-margn-left">
            <div className="res-images">
              <div className="image-container">
                <h1 className="Cormorant-font fw-bold responsive-one-rem">
                  Teal Elegance Embroidered Suit
                </h1>
                <h5 className=" ">BLD24FT037-SML-MNT</h5>
                {/* <div className=" my-3">
                  <button
                    type="button"
                    className="px-4 py-1 btn rounded-0   "
                    style={{ border: "1px solid black" }}
                  >
                    <span className="me-2">
                      <LazyLoadImage effect="blur" src={buttonImage} alt="" />
                    </span>
                    <span>Size Chart</span>
                  </button>
                </div> */}
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Fit type:</span>
                  <span className=""> Regular fit</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Design details:</span>
                  <span>
                    The fitted blazer features intricate botanical embroidery on
                    the lapel and sleeves, complemented by matching tailored
                    trousers with subtle embroidery along the sides. Designed
                    for a sleek look, the blazer includes a single-button
                    closure.
                  </span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Stretch:</span>
                  <span> Minimal.</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Transparency: </span>
                  <span> None</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Hand Feel:</span>
                  <span> Smooth and premium texture</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Lining:</span>
                  <span> Fully lined</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Material: </span>
                  <span>100% wool</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Size:</span>
                  <span> The model is 170 cm/5'7" and wears a size MEDIUM</span>
                </div>
                <div className="py-1 Cormorant-font fit-font-family">
                  <span className="fw-bold ">Occasion:</span>
                  <span> Formal wear</span>
                </div>
                <div className="py-4 Cormorant-font  note-bg">
                  <span className="fw-bold ">Note:</span>
                  <span>
                    {" "}
                    The actual color of the product may vary slightly from the
                    image.
                  </span>
                </div>
              </div>
              <div className="text-container">
                {/* col-md-3  col-xs-3 col-sm-3 */}
                {/* <div className="d-flex">
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womanfour1 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage 
                  effect="blur"
                    onClick={() => handleImageClick(womanfour1)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womanfour1}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womanfour2 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage
                  effect="blur"
                    onClick={() => handleImageClick(womanfour2)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womanfour2}
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 res-top-mar ${
                    selectedImage === womanfour3 ? "selected-image" : ""
                  }`}
                >
                  <LazyLoadImage
                  effect="blur"
                    onClick={() => handleImageClick(womanfour3)}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                    src={womanfour3}
                    alt=""
                  />
                </div>
          
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Womanimhfour;
