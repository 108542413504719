import React from "react";
import Header from "../Global/Header";
import ContactFirstComponent from "./ContactFirstComponent";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function ContactMainComponent() {
  useEffect(() => {
    document.title = "Contact Us | Crowd Approved Fashion";
  }, []);

  return (
    <div className="background-image body-color">
      <Header />
      <ContactFirstComponent />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default ContactMainComponent;
