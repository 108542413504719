import React from "react";
import Header from "../Global/Header";
import WebsiteBreadCrumbs from "../Global/WebsiteBreadCrumbs";
import Manimgtwo from "./Manimgtwo";
import Explorealleight from "../../Components/Explore/Explorealleight";
import Footer from "../Global/Footer";
import CompanyLogo from "../Global/CompanyLogo";
import { useEffect } from "react";

function Manimgonemain() {
  useEffect(() => {
    document.title = "Sunset Serenade Artistic Blazer | Crowd Approved Fashion";
  }, []);

  return (
    <div className="background-image body-color ">
      <Header />
      <WebsiteBreadCrumbs />
      <Manimgtwo />
      <Explorealleight />
      <Footer />
      <CompanyLogo />
    </div>
  );
}

export default Manimgonemain;
